import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { ReactNode } from 'react';

import { CalmTheme } from '@calm-web/design-system';

export const stripeAppearanceOptions: Appearance = {
	labels: 'floating',
	variables: {
		fontFamily: 'Avenir Next, Helvetica Neue, Helvetica, Arial, sans-serif',
		fontLineHeight: '150%',
		fontSizeBase: '18px',
		colorPrimary: CalmTheme.palette.blue3,
		colorText: CalmTheme.palette.gray7,
		colorDanger: CalmTheme.palette.errorRed,
		spacingUnit: '2px',
		tabSpacing: '8px',
		gridColumnSpacing: '16px',
		gridRowSpacing: '16px',
		borderRadius: '6px',
	},
	rules: {
		'.Label': {
			color: CalmTheme.palette.gray5,
			fontWeight: '500',
		},
		'.Label--floating': {
			fontSize: '12px',
			letterSpacing: '0.15px',
		},
		'.Input': {
			border: `1px ${CalmTheme.palette.gray3} solid`,
			color: CalmTheme.palette.gray7,
			fontWeight: '500',
			paddingLeft: '12px',
			paddingRight: '12px',
		},
		'.Input--invalid': {
			border: `2px ${CalmTheme.palette.errorRed} solid`,
			boxShadow: 'unset',
		},
		'.Input:focus': {
			border: `2px ${CalmTheme.palette.blue3} solid`,
			boxShadow: 'unset',
		},
		'.Error': {
			fontSize: '12px',
			fontWeight: '500',
			padding: '4px 0 0 0',
		},
	},
};

const stripePromise = process.env.STRIPE_TOKEN ? loadStripe(process.env.STRIPE_TOKEN) : null;

const StripeElements: React.FC<{
	children: ReactNode;
	mode: StripeElementsOptions['mode'];
	amount?: number;
}> = ({ children, amount, mode }) => {
	return (
		<Elements
			stripe={stripePromise}
			options={{
				amount,
				currency: 'usd',
				mode,
				paymentMethodCreation: 'manual',
				appearance: stripeAppearanceOptions,
				paymentMethodTypes: ['card', 'us_bank_account'],
				payment_method_options: {
					us_bank_account: {
						verification_method: 'instant',
					},
				},
			}}
		>
			{children}
		</Elements>
	);
};

export default StripeElements;
